import React from "react";
import { AiFillYoutube } from "react-icons/ai";

const BodytoneEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">Fitness udstyr fra Bodytone</h2>
      <p>
        Bodytone producerer topprofessionelt fitnessudstyr, til dig der ikke vil
        gå på kompromis med kvaliteten.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Bodytone herunder
      </p>
      <a
        className="underline mt-2 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/bodytone"
        target="_blank"
        rel="noreferrer"
      >
        Se alle Bodytone's produkter her
      </a>
      <a
        href="https://www.youtube.com/c/BODYTONEFitnessEquipment/featured"
        className="flex items-center mt-4 underline"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillYoutube className="mr-2" /> Bodytone's videoer på YouTube
      </a>
    </div>
  );
};

export default BodytoneEquipment;
