export const handleBrands = (productTitle) => {
  const title = productTitle?.[0]?.toLowerCase();
  if (title?.match("odin")) return "Odin";
  if (title?.match("bodytone")) return "Bodytone";
  if (title?.match("nordic fighter")) return "Nordic Fighter";
  if (title?.match("reebok")) return "Reebok";
  if (title?.match("garlando")) return "Garlando";

  return null;
};
