import React from "react";
import Youtube from "react-lazyload-youtube";

const BeurerEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Sund- og skønheds produkter fra Beurer
      </h2>
      <p>
        Beurer er en verdenskendt producent af sundheds samt skønhedsprodukter.
        Beurer producerer alt lige fra bodycare til sportsprodukter og redskaber
        til en bedre nattesøvn.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Beurer herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/beurer"
        target="_blank"
        rel="noreferrer"
      >
        Se Beurer's produkter her
      </a>
      <p className="text-center mb-2">
        Kort video om <b>Beurer</b>
      </p>
      <Youtube height="400px" videoId="XTDyIkVEfvA" />
    </div>
  );
};

export default BeurerEquipment;
