import React from "react";
import Youtube from "react-lazyload-youtube";

const ThorEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Fitness udstyr fra Thor Fitness
      </h2>
      <p>
        Thor Fitness er en af de største producenter i Skandinavien af
        professionelt fitness udstyr. De er særligt kendte for deres vægtskiver,
        men også alt lige fra wallballs, kettlebells og meget mere. Thor Fitness
        gør det også muligt at indrette dit eget professionele fitness lokale,
        da de også sælger udstyr såsom stativ til wallballs og meget andet
        opbevaring.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Thor Fitness
        herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/thor-fitness"
        target="_blank"
        rel="noreferrer"
      >
        Se flere af Thor Fitness produkter her
      </a>
      <p className="text-center mb-2">Video fra Thor Fitness</p>
      <Youtube height="400px" videoId="V4SZ5lsRENw" />
    </div>
  );
};

export default ThorEquipment;
