import React from "react";
import Youtube from "react-lazyload-youtube";

const BoxJump = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Kom i form med en box jump kasse
      </h2>
      <p className="mb-8 leading-8 text-xl">
        Box jump kasser er populære som aldrig før og det giver rigtig god
        mening. Det er nemlig sådan, at med dette enkelte træningselement, kan
        du få brugt alle muskler og led og så endda fra hjemmet. Og så er det
        altså bare sjovere end at løfte vægte. Se videoen her og få ideer til
        hvilke øvelser du kan lave med en box jump kasse.
      </p>
      <h2 className="font-bold mb-8 text-2xl">
        Øvelser du kan lave med en Box jump kasse derhjemme
      </h2>
      <ul>
        <li className="my-4 text-xl leading-8">
          <b>Box jump øvelsen:</b> Dette er en meget grundlæggende øvelse, hvor
          du starter med at stå foran din box jump kasse og springer op på boxen
          med begge fødder på samme tid. For at gøre øvelsen sværere, kan du
          prøve at springe op på boxen med et enkelt ben eller lande på boxen i
          en squat position.
        </li>
        <li className="my-4 text-xl leading-8">
          <b>Step-up øvelsen:</b> Denne øvelse ligner en box jump, men i stedet
          for at springe op på boxen, skal du træde op på den med det ene ben ad
          gangen. Dette er en rigtig god øvelse for at styrke benene og core
          musklerne.
        </li>
        <li className="my-4 text-xl leading-8">
          <b>Box dips øvelsen:</b> For at lave denne øvelse, skal du stå med
          ryggen mod din box jump kasse og læne dig frem med armene helt
          strakte. Bøj derefter dine albuer og sænk dig ned til boxen, indtil
          dine arme er vinkelrette. Derefter skal du presse dig selv op igen ved
          at strække dine arme. Denne øvelse er god for triceps og
          overkropsstyrke.
        </li>
      </ul>
      <p className="my-8 mt-12 text-xl leading-8">
        Med disse 3 nævnte øvelser, får du altså dækket størstedelen af dine
        muskler og core i kroppen, så det er et rigtig godt sted at starte. Dog
        er der mange flere øvelser at foretage og det gør kun træningen sjovere,
        med lidt variation. Du kan se andre gode øvelser med en box jump kasse i
        denne video
      </p>
      <Youtube height="400px" videoId="BeqK8ksNC-E" />
    </div>
  );
};

export default BoxJump;
