import React from "react";
import { AiFillYoutube } from "react-icons/ai";
import Youtube from "react-lazyload-youtube";

const NordicFighterEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Fitness udstyr fra Nordic Fighter
      </h2>
      <p>
        Nordic Fighter er en svensk producent som producerer professionelt
        udstyr til både fitness og kampsport.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Nordic Fighter herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/nordicfighter"
        target="_blank"
        rel="noreferrer"
      >
        Se alle Nordic Fighter's produkter her
      </a>
      <p className="text-center mb-2">Kort video om Nordic Fighter</p>
      <Youtube height="400px" videoId="44GubKVlxME" />
    </div>
  );
};

export default NordicFighterEquipment;
