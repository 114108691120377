import React from "react";
import Youtube from "react-lazyload-youtube";

const Sole = ({ product }) => {
  return (
    <div>
      {product?.toLowerCase().match("f85") && (
        <div>
          <h2 className="font-bold mb-4 text-2xl">
            Derfor har et Sole F85 løbebåndet vundet flere bedst-i-test
          </h2>
          <p className="text-xl mb-4">
            Godt nok ligger et Sole F85 løbebånd i den dyre ende, når vi snakker
            om løbebånd til hjemmet. Men det er det bestemt også en årsag til.
            For det er bare løbebåndet, med en masse unikke features, der kan
            være med til at give dig en bedre løbetur som helhed.
          </p>
          <p className="text-xl mb-4">
            Alene det første øjekast, når man kigger på displayet, får man
            fornemmelsen af at det ikke er et hvilket som helst løbebånd. Et
            Sole F85 løbebånd har både tabletholder, usb-forbindelser og endda
            indbygget højtaler. Udover disse indbyggede features, er det også et
            slidstærk løbebånd, der kan tage op til 150 kg.
          </p>
          <p className="text-xl mb-4">
            Uanset om du søger et løbebånd der kan løfte op til 150 kg og
            begynde på små gåture, eller forbereder dig til at løbe marathon og
            slå personlige rekorder, er et Sole F85 løbebånd helt sikkert den
            rette vej at kigge. Løbebåndet kan komme op på 22 km/t - altså hvad
            der svarer til en pace på 2:43!
          </p>
          <h2 className="text-xl font-bold mb-2">
            Få fornemmelsen af det professionelle Sole F85 løbebånd i denne
            video
          </h2>
          <Youtube height="400px" videoId="2cFTxsY2l0c" />
          <p className="italic text-xs">Video fra SOLE fitness</p>
        </div>
      )}
    </div>
  );
};

export default Sole;
