import React from "react";
import Youtube from "react-lazyload-youtube";

const Nutramino = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Proteinrige snacks og tilskud fra Nutramino
      </h2>
      <p>
        <b>Nutramino</b>. Det er et navn de fleste fitness-entusiaster har hørt
        herhjemme i Danmark. Nutramino er en dansk producent af kosttilskud og
        er kendt for altid at levere en god smag, uanset om det er til
        proteinbar, milkshake eller whey protein.
      </p>
      <p className="mt-2">
        Udover <i>{product}</i> kan du se flere produkter fra Nutramino herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=20604&htmlurl=https://www.bodyman.dk/shop/nutramino-271s1.html"
        target="_blank"
        rel="noreferrer"
      >
        Se Nutramino's produkter her
      </a>
      <p className="text-center mb-2">
        Video fra <b>Nutramino</b>
      </p>
      <Youtube height="400px" videoId="GU8Zkgyi_hw" />
    </div>
  );
};

export default Nutramino;
