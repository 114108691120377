import React from "react";
import Youtube from "react-lazyload-youtube";

export default function ExertubeExercise({ product }) {
  return (
    <>
      <h2 className="font-bold mb-4 text-2xl">
        Derfor er Exertube så populære
      </h2>
      <p>
        <b>Exertube</b> er populære som aldrig før. Et gæt ville være, at man
        under corona-tiden og de lukkede fitness centre, fandt ud af hvor langt
        man egentligt kunne komme, blot ved hjælp af denne form for træning. En
        træningselastik i form af exertube, kan tages med på ferie, og den kan
        hurtigt gemmes væk derhjemme, så den ikke står og fylder som et sæt
        håndvægte. Udover det, har elastikker en stor fordel i, at de altid
        stresser dine muskler og sætter den på arbejde, sammenlignet med
        håndvægte. Elastikken vil altid tilbage som værende "ikke strukket ud",
        hvor man i positioner med håndvægte, kan ramme et punkt hvor man er mere
        som stander, end at stresse musklerne.
        <br />
        <i>
          Der er lige nu rigtig gode tilbud på exertube. Vi håber du finder dem
          du søger her
        </i>
      </p>
      <h2 className="my-4 text-center font-bold">Se øvelser med Exertube</h2>
      <Youtube height="400" videoId="IPQVJkuVaJU" />
    </>
  );
}
