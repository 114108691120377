import React from "react";
import { Link } from "gatsby";
import SEO from "../components/SEO";
import { GiShoppingCart } from "react-icons/gi";
import { GrMoney } from "react-icons/gr";
import { handleDescription } from "../utils/handleDescription";
import popularContent from "../popularProducts";
import { handleBrands } from "../utils/handleBrands";
import popularExercises from "../exercises";
import Product from "../components/Product";
import { JSXSchema } from "../components/JSXSchema";
import { handleEncoding } from "../utils/handleEncoding";

function ProductTemplate({
  pageContext: {
    productTitle,
    productPath,
    productId,
    productImage,
    productDescription,
    productPrice,
    productOldprice,
    productLink,
    productCategory,
    productType,
    productAvailable,
    aiGenerated,
    productBrand,
    productSiblings,
  },
}) {
  const handleUrl = (product) =>
    product
      .replace(/\s/g, "-")
      .replace(/-+/g, "-")
      .replace(/[^a-å0-9-]/gi, "")
      .toLowerCase();
  return (
    <>
      <SEO
        title={handleEncoding(productTitle)}
        description={`Læs mere omkring ${handleEncoding(
          productTitle
        )} og se hvor du netop kan købe den`}
      />
      <JSXSchema
        type="Product"
        name={productTitle[0]}
        brand={productBrand}
        image={productImage[0]}
        sku={String(new Date().getTime())}
        description={handleDescription(productDescription)?.[0]}
        offers={{
          type: "Offer",
          price: productPrice[0],
          priceCurrency: "DKK",
          availability: "In stock",
        }}
        aggregateRating={{
          type: "AggregateRating",
          ratingValue: "4.8",
          reviewCount: "11",
        }}
      />
      <div className="pb-16 lg:pb-4 px-4 pt-8 m-auto bg-white rounded-lg">
        <div className="mx-auto flex items-center flex-col lg:flex-row justify-center">
          <a
            href={productLink}
            target="_blank"
            rel="noreferrer nofollow"
            className="cursor-zoom-in product-img"
          >
            <img
              height="280"
              width="280"
              className="w-full md:w-72 object-contain max-h-full"
              src={productImage}
              alt={productTitle}
            />
          </a>
          <div className="mt-4 lg:mt-0 lg:ml-4">
            <h1 className="font-bold text-2xl max-w-xl">
              {handleEncoding(productTitle)}
            </h1>
            {handleBrands(productTitle) && (
              <span className="text-xs font-bold">
                Mærke: {handleBrands(productTitle)}
              </span>
            )}
            <div className="mt-4 mb-4">
              <p className="font-bold text-xl">Fundet til {productPrice} kr</p>
              {productOldprice &&
              Number(productOldprice) > Number(productPrice) ? (
                <>
                  <p className="opacity-40 line-through text-xs mb-4">
                    {productOldprice} kr
                  </p>
                  <p className="font-bold flex items-center">
                    <GrMoney className="mr-2" />
                    Du sparer: {Number(productOldprice) -
                      Number(productPrice)}{" "}
                    kr
                  </p>
                </>
              ) : (
                <p>&nbsp;</p>
              )}
            </div>
            <a
              href={productLink}
              className="btn go-to-shop text-2xl mt-2 text-center green my-4 font-bold"
              target="_blank"
              rel="noreferrer nofollow"
            >
              Gå til butik
              <span className="block px-2 pt-1">
                <GiShoppingCart />
              </span>
            </a>
          </div>
        </div>
        <div className="mt-12 max-w-4xl mx-auto">
          {handleDescription(productDescription)?.[0] && (
            <div className="pt-4 border-t border-gray-300">
              <h2 className="font-bold mb-4 text-2xl">
                Kort beskrivelse af {handleEncoding(productTitle)}
              </h2>
              <p className="text-xl">
                {handleDescription(productDescription)[0]}
              </p>
              {productOldprice &&
                Number(productOldprice) > Number(productPrice) && (
                  <h3 className="mt-4 font-bold text-xl">
                    {handleEncoding(productTitle)} er på tilbud og{" "}
                    <a
                      className="underline"
                      href={productLink}
                      target="_blank"
                      rel="noreferrer nofollow"
                    >
                      kan købes her
                    </a>
                  </h3>
                )}
            </div>
          )}
          {aiGenerated && (
            <div className="mt-8 pt-8 border-t">
              <p className="whitespace-pre-line text-xl">
                {handleDescription(aiGenerated.replace("\n\n\n\n", ""))}
              </p>
            </div>
          )}
          {popularExercises(productTitle) && (
            <div className="border-t border-gray-300 pt-12 mt-12">
              {popularExercises(productTitle)}
            </div>
          )}
          {popularContent(productTitle) && (
            <div className="border-t border-gray-300 pt-12 mt-12">
              {popularContent(productTitle)}
            </div>
          )}
          <div className="mt-24">
            {productSiblings && (
              <>
                <h2 className="font-bold mb-4 text-2xl">
                  Andre{productBrand && ` ${productBrand}`} produkter{" "}
                  {!productBrand && "fra samme kategori"}
                </h2>
                <div
                  className={`flex flex-wrap md:flex-nowrap ${
                    productSiblings.length > 0 ? "justify-center" : ""
                  }`}
                >
                  {productSiblings.map((pro) => (
                    <Product
                      key={`${pro.shopId || 1}${
                        pro.produktid?.[0] || pro.SKU?.[0]
                      }`}
                      title={pro.produktnavn?.[0] || pro.Name?.[0]}
                      image={pro.billedurl?.[0] || pro.ImageUrl?.[0]}
                      productPath={`/${pro.productType}/${handleUrl(
                        pro.produktnavn?.[0] || pro.Name?.[0]
                      )}`}
                      productPrice={pro.nypris?.[0] || pro.Price?.[0]}
                      productOldprice={
                        pro.glpris?.[0] ||
                        pro.nypris?.[0] ||
                        pro.OriginalPrice?.[0]
                      }
                      productLink={pro.vareurl?.[0] || pro.TrackingUrl?.[0]}
                      productId={`${pro.shopId || 1}${
                        pro.produktid?.[0] || pro.SKU?.[0]
                      }`}
                    />
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="mt-24">
            <JSXSchema
              type="BreadcrumbList"
              itemListElement={[
                {
                  type: "ListItem",
                  position: 1,
                  item: { id: "https://www.billigform.dk/", name: "Forside" },
                },
                {
                  type: "ListItem",
                  position: 2,
                  item: {
                    id: `https://www.billigform.dk/${productType}`,
                    name: productType,
                  },
                },
              ]}
            />
            <ul className="breadcrumbs text-xs flex gap-2 uppercase">
              <li className="underline">
                <Link exact="true" to="/">
                  Hjem
                </Link>
              </li>
              <li>/</li>
              <li className="underline">
                <Link exact="true" to={`/${productType}`}>
                  {productType}
                </Link>
              </li>
              <li>/</li>
              <li className="font-bold">{productTitle}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductTemplate;
