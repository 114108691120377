import React from "react";
import Youtube from "react-lazyload-youtube";

const BodylabEquipment = ({ product }) => {
  return (
    <div className="text-xl">
      {product?.toLowerCase().match("bodylab whey") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Hvad er whey proteinpulver?
          </h2>

          <p className="my-4">
            Whey proteinpulver er fremstillet af mælkevalle, der er den væske,
            der opstår, når mælk koagulerer under produktion af ost. Mælkevalle
            er rig på protein, og whey proteinpulver er et koncentrat af dette
            protein. Der er tre typer whey proteinpulver: koncentrat, isolat og
            hydrolysat. Koncentrat er den billigste og mest almindelige form for
            whey proteinpulver og indeholder typisk 70-80% protein. Isolat er
            mere rent og indeholder normalt 90% protein eller mere. Hydrolysat
            er blevet forbehandlet, så proteinet er delvist fordøjet, hvilket
            gør det lettere at absorbere for kroppen.
          </p>

          <h2 className="font-bold mb-4 text-2xl">
            Hvad er fordelene ved at tage whey proteinpulver?
          </h2>

          <p className="my-4">
            Whey proteinpulver er populært blandt træningsentusiaster, da det er
            en nem og bekvem måde at øge proteinindtaget på. Proteiner er
            afgørende for muskelvækst og vedligeholdelse, så whey proteinpulver
            kan hjælpe med at opbygge og bevare muskler. Udover muskelopbygning
            kan whey proteinpulver også hjælpe med at mindske sult og støtte
            vægttab. Protein er mere mættende end kulhydrater og fedt, så ved at
            indtage mere protein kan man føle sig mæt i længere tid og dermed
            spise mindre. Derudover kræver protein mere energi at fordøje end
            kulhydrater og fedt, hvilket betyder, at kroppen bruger flere
            kalorier på at fordøje protein, hvilket kan bidrage til vægttab.
          </p>

          <h2 className="font-bold mb-4 text-2xl">
            Hvordan skal man tage whey proteinpulver?
          </h2>

          <p className="my-4">
            Whey proteinpulver kan tages som en del af ens daglige kost, og det
            kan nemt blandes med vand eller mælk. Det kan også bruges som
            ingrediens i smoothies og andre måltider. Det er vigtigt at følge de
            anbefalede doseringsinstruktioner på produktet, da for meget protein
            kan være skadeligt for kroppen. Generelt anbefales det at tage
            mellem 20-30 gram whey proteinpulver pr. dag.
          </p>
        </>
      )}
      <h2 className="font-bold mb-4 text-2xl">Kosttilskud fra Bodylab</h2>
      <p>
        Bodylab er Danmarks største producent af sportsprodukter, proteinpulver,
        kreatin og andre kosttilskud.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Bodylab herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=11522"
        target="_blank"
        rel="noreferrer"
      >
        Se Bodylabs produkter her
      </a>
      <p className="text-center mb-2">
        Video om <b>Bodylabs whey 100 proteinpulver</b>
      </p>
      <Youtube height="400px" videoId="THu5smMDIM8" />
    </div>
  );
};

export default BodylabEquipment;
