export const titleCleanup = (title) => {
  if (typeof title === "undefined") return "";
  if (typeof title === "string") {
    if (!title) return "";
    return title
      .replace(/â€¨/g, "")
      .replace(/&amp;/g, "&")
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, "â")
      .replace(/iÌˆ/g, "ï")
      .replace(/Ã´/g, "ô")
      .replace(/Ã¨/g, "è")
      .replace(/Å¾/g, "ž")
      .replace(/Ã‰/g, "é")
      .replace(/eÌ/g, "é")
      .replace(/eì/g, "é")
      .replace(/Ã“/g, "ó")
      .replace(/Ã±/g, "ñ")
      .replace(/â€œ/g, '"')
      .replace(/ã/g, "é")
      .replace(/â¨/g, "")
      .replace(/Ã¬/g, "ì")
      .replace(/ã/g, "é")
      .replace(/â/g, "'")
      .replace(/â€/g, '"');
  } else {
    if (!title[0]) return "";
    return title[0]
      .replace(/â€¨/g, "")
      .replace(/&amp;/g, "&")
      .replace(/â€™/g, "'")
      .replace(/Ã¢/g, "â")
      .replace(/iÌˆ/g, "ï")
      .replace(/Ã´/g, "ô")
      .replace(/Ã¨/g, "è")
      .replace(/Å¾/g, "ž")
      .replace(/Ã‰/g, "é")
      .replace(/eÌ/g, "é")
      .replace(/eì/g, "é")
      .replace(/Ã“/g, "ó")
      .replace(/Ã±/g, "ñ")
      .replace(/â€œ/g, '"')
      .replace(/ã/g, "é")
      .replace(/â¨/g, "")
      .replace(/Ã¬/g, "ì")
      .replace(/ã/g, "é")
      .replace(/â/g, "'")
      .replace(/â€/g, '"');
  }
};
