import React from "react";
import CrossfitHammerExercise from "./crossfithammer";
import ExertubeExercise from "./exertube";

const popularExercises = (productTitle) => {
  const title = productTitle?.[0]?.toLowerCase();

  if (title?.match("exertube")) {
    return <ExertubeExercise product={title} />;
  }

  if (title?.match("crossfit hammer")) {
    return <CrossfitHammerExercise product={title} />;
  }

  return null;
};

export default popularExercises;
