import React from "react";
import Youtube from "react-lazyload-youtube";

const ReebokEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">Udstyr fra Reebok</h2>
      <p>
        Reebok er en af verdens absolut største spillere på det sportslige
        markede.
      </p>
      <p>
        Udover <i>{product}</i> kan du se flere produkter fra Reebok herunder
      </p>
      <a
        className="underline mt-2 mb-8 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/reebok"
        target="_blank"
        rel="noreferrer"
      >
        Se flere af Reebok's produkter her
      </a>
      <p className="text-center mb-2">Video fra Reebok</p>
      <Youtube height="400px" videoId="ejWs-0uyva0" />
    </div>
  );
};

export default ReebokEquipment;
