import React from "react";
import Youtube from "react-lazyload-youtube";

const GarlandoEquipment = ({ product }) => {
  return (
    <div>
      <h2 className="font-bold mb-4 text-2xl">
        Derfor skal du vælge Garlando's bordfodbold udstyr
      </h2>
      <p>
        Garlando er ofte det mærke, du vil se blive anvendt til de helt store
        turneringer, som de professionele spiller på. Garlando har også deres
        egen, internationale turnering.
      </p>
      <p className="my-8">
        Udover <i>{product}</i> kan du se flere bordfodbold produkter fra
        Garlando herunder
      </p>
      <p className="text-center mb-2">Kamp spillet på bordfodbold bord fra Garlando</p>
      <Youtube height="400px" videoId="PyiH0iYZGVE" />
    </div>
  );
};

export default GarlandoEquipment;
