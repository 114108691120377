import React from "react";
import Youtube from "react-lazyload-youtube";

export default function CrossfitHammerExercise({ product }) {
  return (
    <>
      <h2 className="font-bold mb-4 text-2xl">
        Hvorfor er Crossfit hammer så populære?
      </h2>
      <p>
        <b>Crossfit hammer</b> (også kaldet sledge hammer) er til dig der ønsker
        en funktionel træning. Du tænker måske, at bruge en "hammer" til sin
        træning lyder lidt specielt. Men det er faktisk intet mindre end
        genialt. Med en crossfit hammer får du automatisk hele kroppen i
        omdrejninger og det vil du bestemt kunne mærke! Typisk vil du se
        crossfit hammer blive brugt til at hamre i store dæk, hvilket også er
        mest praktisk, men det er ikke påkrævet. Andre gummi elementer kan også anvendes.
        <br />
        <i>
          Der er lige nu rigtig gode tilbud på Crossfit hammer. Vi håber du
          finder dem du søger her
        </i>
      </p>
      <h2 className="my-4 text-center font-bold">
        Se tips til Crossfit hammer
      </h2>
      <Youtube height="400px" videoId="SYQF1AHyDSU" />
    </>
  );
}
