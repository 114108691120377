import { Link } from "gatsby";
import React from "react";
import Youtube from "react-lazyload-youtube";

const OdinEquipment = ({ product }) => {
  return (
    <div className="text-xl">
      {product?.toLowerCase().match("odin b800 bluetooth motionscykel") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Odin B800 Bluetooth motionscykel - Et godt valg til hjemmet
          </h2>
          <p className="my-4">
            På en Odin B800 vil du se en flot skærm i tablet størrelse, som er
            med til at give dig en mere spændende cykeltur. Samt kinomap vil du
            kunne se tid, hastighed, kalorietælling med mere.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Featuren ved bluetooth på en Odin B800
          </h2>
          <p className="my-4">
            Ved at en Odin B800 har bluetooth, gør det muligt at koble den op på
            enten din telefon eller tablet, hvor du her kan vælge programmer og
            følge med i statistikker på tidligere cykelture du har haft. Alt i
            alt en rigtig god cykel til en fornuftig pris.
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin t750") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Odin T750 Løbebånd - Det Ultimative Løbeoplevelse i Hjemmet
          </h2>

          <p className="my-4">
            At have et løbebånd i hjemmet er en fantastisk måde at opretholde en
            aktiv livsstil på. Odin T750 Løbebåndet er en af ​​de mest populære
            muligheder til hjemmebrug, og det er ikke svært at se hvorfor. Med
            sine avancerede funktioner og brugervenlige design kan det give dig
            en ultimativ løbeoplevelse i komforten af dit eget hjem.
          </p>

          <h2 className="font-bold mb-4 text-2xl">Design og byggekvalitet</h2>

          <p className="my-4">
            Odin T750 er en af de mest robuste løbebånd på markedet. Den massive
            3HK motor er i stand til at levere en hastighed på op til 22 km/t,
            hvilket er mere end nok til at tilfredsstille selv de mest krævende
            løbere. Den 3-lags løbeflade er designet til at absorbere stød og
            minimere belastningen på dine led og muskler. Derudover er rammen
            fremstillet af kraftig stål, hvilket sikrer stabilitet og
            holdbarhed.
          </p>

          <h2 className="font-bold mb-4 text-2xl">Brugeroplevelse</h2>

          <p className="my-4">
            Odin T750 er også utroligt brugervenligt. Det har en stor LCD-skærm,
            der giver dig nem adgang til alle de vigtige oplysninger, såsom din
            hastighed, afstand, tid og kalorieforbrænding. Skærmen har også en
            indbygget ventilator, der kan hjælpe med at holde dig kølig og
            behagelig, mens du træner. Derudover har løbebåndet en række
            indbyggede træningsprogrammer og en trådløs pulsmåler, der kan
            hjælpe dig med at opnå dine træningsmål.
          </p>

          <h2 className="font-bold mb-4 text-2xl">Pladsbesparende</h2>

          <p className="my-4">
            Odin T750 er også designet med pladsbesparelse i tankerne. Når du
            ikke bruger det, kan du nemt folde løbebåndet sammen og gemme det
            væk, så det ikke optager unødvendig plads. Det gør det til et
            perfekt valg til små lejligheder eller hjem med begrænset plads.
            <br />
            <br />
            Odin T750 Løbebånd er en af ​​de bedste løbebånd til hjemmebrug. Med
            dens robuste konstruktion, avancerede funktioner og brugervenlige
            design kan det give dig en ultimativ løbeoplevelse i dit eget hjem.
            Hvis du vil have en løbeoplevelse i verdensklasse uden at forlade
            dit hjem, så er Odin T750 løbebåndet det rigtige valg for dig.
          </p>
        </>
      )}
      {product
        ?.toLowerCase()
        .match("odin circulation trainer motionscykel") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Odin Circulation Trainer - Siddecykel til hjemmet og kontoret
          </h2>
          <p className="my-4">
            En siddecykel kan være en god tilføjelse til enhver
            kontorindstilling. Ikke alene kan den hjælpe med at forbedre din
            generelle sundhed, men den kan også hjælpe med at øge din
            produktivitet på arbejdet.
          </p>
          <h2 className="font-bold mb-4 text-2xl">Hvad er en siddecykel?</h2>
          <p className="my-4">
            En siddecykel, også kendt som en pedalcykel, er en lille cykel, der
            kan placeres under dit skrivebord. Cyklen har pedaler, som du kan
            bruge til at cykle, mens du sidder ned. Siddecykler kan variere i
            størrelse, form og funktion. Nogle har en justerbar modstand, så du
            kan øge eller mindske intensiteten, mens andre kan have indbyggede
            skærme, der viser din hastighed, distance og kalorier forbrændt.
            Odin Circulation Trainer Siddecykel har et display, hvor du kan
            justere dette.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Fordele ved at bruge en siddecykel på kontoret
          </h2>
          <p className="my-4">
            At sidde ned i lange perioder kan have en negativ indvirkning på din
            sundhed. Det kan øge risikoen for overvægt, diabetes, hjertesygdomme
            og andre sundhedsproblemer. At have en siddecykel på dit kontor kan
            hjælpe med at bekæmpe disse risici og forbedre din generelle
            sundhed. Brug af en siddecykel kan også hjælpe med at øge din
            produktivitet på arbejdet. Forskning har vist, at træning kan hjælpe
            med at øge blodgennemstrømningen til hjernen, hvilket kan forbedre
            din hjernefunktion og kognitive evner. Dette kan hjælpe dig med at
            forblive mere fokuseret og produktiv, mens du arbejder.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Hvad skal man overveje, når man køber en siddecykel?
          </h2>

          <p className="my-4">
            Når du køber en siddecykel til brug på kontoret, er der flere
            faktorer, du bør overveje:
            <ul>
              <li className="my-4">
                <h3 className="font-bold">Størrelse og vægt</h3> Siddecyklen
                skal være let at transportere og opbevare på kontoret. Vælg en
                model, der er let og kompakt nok til at passe under dit
                skrivebord.
              </li>

              <li className="my-4">
                <h3 className="font-bold">Modstand</h3> Nogle siddecykler har en
                justerbar modstand, der giver dig mulighed for at øge eller
                mindske intensiteten. Vælg en model med en modstand, der passer
                til dit træningsniveau.
              </li>

              <li className="my-4">
                <h3 className="font-bold">Støj</h3> Nogle siddecykler kan være
                støjende, hvilket kan være distraherende på kontoret. Vælg en
                model, der er stille og ikke vil forstyrre dine kolleger.
              </li>

              <li className="my-4">
                <h3 className="font-bold">Skærm</h3> Hvis du vil overvåge din
                træning, kan det være nyttigt at have en cykel med en indbygget
                skærm, der viser din hastighed, distance og kalorier forbrændt.
              </li>

              <li className="my-4">
                <h3 className="font-bold">Komfort</h3> Siddecyklen skal være
                behagelig at bruge, selv når du sidder ned i lange perioder.
                Vælg en model med justerbare pedaler, så du kan finde den
                rigtige position.
              </li>
            </ul>
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin r650") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Vi anbefaler Odin R650 med kinomap
          </h2>
          <p className="my-4">
            Odin R650 er vores bedst-i-test. når vi snakker romaskiner til
            hjemmebrug. Uden at være på tilbud, er den til at betale, men stadig
            i højest kvalitet, som giver alle en god og solid tur på romaskinen.
            Samtidig er den kompakt og derfor et rigtig godt bud, når vi snakker
            romaskine til hjemmet.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Hvad kan jeg se på Odin R650 display?
          </h2>
          <p className="my-4">
            Skærmen er nem og brugervenlig, så det er lige til at komme i gang.
            <br />
            Af oplysninger finder du blandet andet distance, kalorietælling og
            åretag.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Hvad giver kinomap af muligheder?
          </h2>
          <p className="my-4">
            Kinomap er en stor fordel af have, når du er kommet godt i gang med
            gøre brug af romaskinen. Her får du nemlig muligheden for, at
            forbinde din Odin R650, med din iPad som kan placeres i iPad
            holderen. Det giver muligheden for en virtuel træning, som kan give
            motivation og en bedre ro-oplevelse, til dig der være mere ambitiøs
            eller bare godt til lige træningslegetøj.
          </p>
          <h2 className="font-bold mb-4 text-2xl">
            Odin R650 mål og funktionaliteter
          </h2>
          <p className="my-4">
            Romaskinen måler 162 i længde, 53,7 i bredde og 87,8 i højden.
            Samtidig kan den foldes sammen ved rejse den op, så den med lethed
            kan placeres, uden at fylde for meget. Endnu en god årsag til at vi
            anbefaler den til hjemmet. Odin R650 vejer lige knap 22 kg, og kan
            holde til en brugervægt på 130 kg. Du har mulighed for justerbare på
            modstandsstyrken manuelt.
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin t200") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Kom i form derhjemme med et Odin T200 gåbånd
          </h2>
          <p className="mb-8">
            Power walking er også en effektiv måde at forbrænde kalorier på. At
            gå hurtigt bruger mere energi end at gå i normalt tempo, og derfor
            kan det hjælpe med at øge dit kalorieforbrug. Dette kan være
            nyttigt, hvis du ønsker at tabe dig eller opretholde en sund vægt.
            <br />
            <br />
            En anden fordel ved et <b>Odin T200</b> er, at det er en skånsom
            form for motion. Det sætter ikke samme belastning på dine led som
            løb eller højintensiv træning, og det kan derfor være en god måde at
            træne på, hvis du har problemer med led eller andre skader.
            <br />
            <br />
            Power walking på et <b>Odin T200</b> er en nem og praktisk måde at
            motionere på. Du behøver ikke noget specielt udstyr ud over
            behagelige sko og tøj. Du kan også gøre det næsten overalt, enten
            udendørs eller indendørs på en løbebånd. Det betyder, at det er en
            form for motion, som de fleste mennesker kan udføre, uanset deres
            alder, fitnessniveau eller tidligere erfaring med motion.
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin") &&
        product?.toLowerCase().match("crosstrainer") && (
          <>
            <h2 className="font-bold mb-4 text-2xl">
              Derfor er Odin Crosstrainer et hit til hjemmet
            </h2>
            <p className="mb-8">
              Ved at træne på en <b>odin crosstrainer</b> får du både pulsen op
              og styrket dit hjerte-kar-system, samtidig med at du skåner dine
              led og muskler. Crosstraineren er nemlig designet til at give en
              lav belastning på knæ, hofter og ankler, hvilket gør det til en
              ideel træningsform for personer med skader eller smerter i disse
              områder.
              <br />
              <br />
              En anden fordel ved at træne på en crosstrainer er, at det er en
              helkropstræning. Ved at træne på crosstraineren aktiverer du både
              dine arme, ben, ryg og mavemuskler, hvilket gør det til en
              effektiv måde at træne hele kroppen på samtidig. Dette gør odin
              crosstraineren til en god træningsmaskine, hvis du ønsker at
              opbygge muskelstyrke og forbedre din kondition.
              <br />
              <br />
              En tredje fordel ved at træne på en crosstrainer er, at det kan
              hjælpe dig med at forbrænde kalorier og tabe dig. Crosstraineren
              er en effektiv form for kardiovaskulær træning, der kan hjælpe dig
              med at forbrænde fedt og kalorier. Ved at træne regelmæssigt på en
              odin crosstrainer kan du derfor opnå et vægttab, hvis du
              kombinerer træningen med en sund kost.
            </p>
          </>
        )}
      {product?.toLowerCase().match("odin basketstander") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Derfor er Odin basketstander så populær
          </h2>
          <p className="mb-8">
            Odins basketstander er populær, fordi den passer til alles behov. Er
            det til træningsbanen eller indkørslen hjemme i privaten - den er
            til at have med at gøre alle steder. Basketstanderen er i høj
            kvalitet og svær at vælte. <br />
            Derudover har den den store fordel, at den er til at justere og
            tilpasses altså med lethed alle højder og aldre.
            <br />
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin t800") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Kom godt i gang på et Odin T800 Løbebånd
          </h2>
          <p className="mb-8">
            Et Odin T800 løbebånd er et rigtig godt valg, til dig der vil til at
            lave en ændring for din sundhed og dit helbred. <br />
            Med understøttelse til kinomap, kan du forbinde Odin T800 med din
            iPad og gøre gå- eller løbeturen på løbebåndet ekstra spændende.
          </p>
          <p className="mb-8">
            Mærket Odin er et svensk mærke, som er kendt for altid at levere
            produkter i en meget høj kvalitet. Det sikrer dig at ved at købe et
            Odin T800 Løbebånd, er du meget godt dækket i mange år frem i tiden.
            Invester i din sundhed ved at købe et Odin T800. <br />
            Det kan være at du er ved at gøre klar til et halvmarathon eller
            blot vil op og ramme 10.000 skridt. Vi anbefaler nemlig stærkt
            løbebånd fra Odin.
          </p>
        </>
      )}
      {product?.toLowerCase().match("odin r1 walking pad") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Øg de daglige skridt på en Odin R1 Walking Pad
          </h2>
          <p className="mb-8">
            Vi arbejder fleksibelt og hjemmefra mere end nogensinde før.
            <br />
            Det er der mange fordele i. Sidder du til dagligt og arbejder på
            computeren, <br />
            kan en Odin R1 Walking Pad hjælpe dig med at opnå flere skridt uden
            de store ændringer i arbejdsdagen.
            <br />
            Det er nemlig en walking pad der er nem at gå på under skrivebordet.
            Start med at gå langsomt i 30 minutter
            <br />
            På denne måde kan du med lethed gå flere kilometer dagligt.
            <br />
          </p>
        </>
      )}
      <h2 className="font-bold mb-4 text-2xl">Fitness udstyr fra Odin</h2>
      <p>
        Er Odin mærket på fitness udstyret, er du sikret den højeste kvalitet
        indenfor udstyr til hjemmefitness.
      </p>
      <p>
        Udover <i>{product}</i> kan du finde alt fra løbebånd, spinningscykler,
        bænkstativer, basketkurve, håndvægte og listen fortsætter. Der er næsten
        ikke det sportsudstyr, du ikke kan finde når det kommer fra Odin's
        udstyr.
      </p>
      <p className="my-4">
        Odin er særligt populære for deres{" "}
        <Link className="underline" to="/andet/odin-r1-walking-pad">
          Walking Pad
        </Link>
        ,{" "}
        <Link className="underline" to="/andet/odin-t800-løbebånd">
          Odin T800 Løbebånd
        </Link>{" "}
        og deres{" "}
        <Link className="underline" to="/andet/odin-r900-romaskine">
          Odin R900 romaskine
        </Link>
      </p>
      <a
        className="underline my-2 block"
        href="https://www.partner-ads.com/dk/klikbanner.php?partnerid=28634&bannerid=39091&htmlurl=https://apuls.dk/brands/odin"
        target="_blank"
        rel="noreferrer nofollow"
      >
        Se alle Odin's produkter her
      </a>
      {product?.toLowerCase().match("romaskine") && (
        <>
          <h2 className=" mb-2 font-bold mt-8 text-2xl">
            Guide til brug af Odin romaskine
          </h2>
          <p className="mb-4">
            Du kan se denne video og få en ide om hvordan en romaskine fra Odin
            benyttes. I videoen præsenteres flere romaskiner fra Odin, men
            teknikken er mere eller mindre den samme, hvis du roer på en{" "}
            <b>{product}</b>
          </p>
          <Youtube height="400px" videoId="heHOujDceLg" />
        </>
      )}
      {product?.toLowerCase().match("løbebånd") && (
        <>
          <h2 className=" mb-2 font-bold mt-8 text-2xl">
            Gode råd til løbebånd
          </h2>
          <p className="mb-4">
            Få gode råd til hvordan du kommer i gang med et løbebånd.
          </p>
          <Youtube height="400px" videoId="3e_aZ6298SM" />
        </>
      )}
    </div>
  );
};

export default OdinEquipment;
