import React from "react";

const AbilicaEquipment = ({ product }) => {
  return (
    <div className="text-xl">
      {product?.toLowerCase().match("abilica xs 3.0") && (
        <>
          <h2 className="font-bold mb-4 text-2xl">
            Cykel dig i form på en Abilica XS 3.0
          </h2>
          <p className="my-4">
            Vi vil vurdere Abilica XS 3.0 til at være en af de bedste
            spinningscykler til prisen, <br />
            til dig der gerne vil have en spinningscykel til hjemmet.
          </p>
        </>
      )}
      <h2 className="font-bold mb-4 text-2xl"></h2>
    </div>
  );
};

export default AbilicaEquipment;
