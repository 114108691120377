import React from "react";
import BeurerEquipment from "./beurer";
import BodylabEquipment from "./bodylab";
import BodytoneEquipment from "./bodytone";
import BoxJump from "./boxjump";
import GarlandoEquipment from "./garlando";
import NordicFighterEquipment from "./nordicfighter";
import Nutramino from "./nutramino";
import OdinEquipment from "./odin";
import ReebokEquipment from "./reebok";
import ThorEquipment from "./thorfitness";
import AbilicaEquipment from "./abilica";
import Sole from "./sole";

const popularContent = (productTitle) => {
  const title = productTitle?.[0]?.toLowerCase();
  if (title?.match("odin")) {
    return <OdinEquipment product={title} />;
  }

  if (title?.match("sole")) {
    return <Sole product={title} />;
  }

  if (title?.match("bodytone")) {
    return <BodytoneEquipment product={title} />;
  }

  if (title?.match("nordic fighter")) {
    return <NordicFighterEquipment product={title} />;
  }

  if (title?.match("beurer")) {
    return <BeurerEquipment product={title} />;
  }

  if (title?.match("bodylab")) {
    return <BodylabEquipment product={title} />;
  }

  if (title?.match("reebok")) {
    return <ReebokEquipment product={title} />;
  }

  if (title?.match("nutramino")) {
    return <Nutramino product={title} />;
  }

  if (title?.match("thor fitness")) {
    return <ThorEquipment product={title} />;
  }

  if (title?.match("garlando")) {
    return <GarlandoEquipment product={title} />;
  }

  if (title?.match("box jump kasse")) {
    return <BoxJump product={title} />;
  }

  if (title?.match("abilica")) {
    return <AbilicaEquipment product={title} />;
  }

  return null;
};

export default popularContent;
